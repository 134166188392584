import React from "react";
import { Link } from "gatsby";

//components
import Layout from "../components/layout";
import AbReveal from "../components/abReveal/abReveal";
import AbWatermark from "../components/abWatermark/abWatermark";
import CookiePopup from "../components/cookiePopup/cookiePopup";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <AbReveal />
      <CookiePopup />
      <AbWatermark />

      {/* Header */}
      <div className="container pt-5">
        <div className="row">
          <div className="col">
            <Link to="/"><img src="/assets/imgs/logo-ab.svg" alt="Logo Andrea Balbo" width="50"/></Link>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col">

            <p className="h2 text-justify">Privacy Policy of andreabalbo.com</p>
            <p className="text-justify">andreabalbo.com collects some Personal Data of its Users.</p>

            <p className="h3 mt-4 text-justify">Owner of Data Treatment</p>
            <p className="text-justify">
              andreabalbo.com
              <br />Owner's email address:&nbsp;
              <a href="mailto:info@andreabalbo.com" className="dark-link">info@andreabalbo.com</a>
            </p>

            <p className="h3 mt-4 text-justify">Types of Data Collected</p>
            <p className="text-justify">
            Among the Personal Data collected by andreabalbo.com, independently or through third parties, there are: name, surname, sex, date of birth, telephone number, VAT number, company name, profession, address, fax number, country, state, province, email, zip code, various types of Data, city, tax code, business sector, User ID, number of employees, website, cookies and Usage Data. Full details on each type of data collected are provided in the dedicated sections of this privacy policy or by means of specific information texts displayed before the data collection. Personal Data can be freely provided by the User or, in the case of Usage Data, automatically collected during the use of andreabalbo.com. Unless otherwise specified, all Data requested by andreabalbo.com is mandatory. If the User refuses to provide it, it may be impossible for andreabalbo.com to provide the Service. In cases where andreabalbo.com indicates certain Data as optional, Users are free to refrain from communicating such Data, without this having any effect on the availability of the Service or its operation. Users who have any doubts about which Data is mandatory, are encouraged to contact the Data Controller. Any use of Cookies - or other tracking tools - by andreabalbo.com or the owners of third party services used by andreabalbo.com, unless otherwise specified, is intended to provide the Service requested by the User, in addition to the further purposes described in this document and in the Cookie Policy, if available. The User assumes responsibility for the Personal Data of third parties obtained, published or shared through andreabalbo.com and guarantees that he or she has the right to communicate or disseminate it, releasing the Owner from any responsibility towards third parties.
            </p>

            <p className="h3 mt-4 text-justify">Modality and treatment place of collected data</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Treatment modalities</p>
            <p className="text-justify">
            The Owner adopts appropriate security measures to prevent unauthorized access, disclosure, modification or destruction of Personal Data. The processing is carried out by computer and/or telematic means, with organizational methods and logics strictly related to the purposes indicated. In addition to the Data Controller, in some cases, other subjects involved in the organization of andreabalbo.com (administrative, commercial, marketing, legal, system administrators) or external subjects (such as third party technical service providers, postal couriers, hosting providers, computer companies, communication agencies) may have access to the Data also appointed, if necessary, as Data Processors by the Data Controller. The updated list of Managers can always be requested from the Data Controller.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Legal basis of the treatment</p>
            <p
              className="text-justify mb-0"
            >The Owner processes Personal Data relating to the User if one of the following conditions is met:</p>
            <ul className="list-unstyled mb-0">
              <li>- the User has given his/her consent for one or more specific purposes; Note: in some jurisdictions the Data Controller may be authorized to process Personal Data without the User's consent or another of the legal bases specified below, until the User objects ("opt-out") to such processing. However, this is not applicable if the processing of Personal Data is governed by European legislation on the protection of Personal Data.</li>
              <li>- the treatment is necessary for the execution of a contract with the User and/or the execution of pre-contractual measures.</li>
              <li>- the treatment is necessary to fulfill a legal obligation to which the Owner is subject.</li>
              <li>- the treatment is necessary for the performance of a task in the public interest or for the exercise of public authority vested in the Owner.</li>
              <li>- the processing is necessary for the pursuit of the legitimate interest of the Owner or third parties.</li>
            </ul>
            <p>However, it is always possible to ask the Owner to clarify the concrete legal basis of each treatment and in particular to specify whether the treatment is based on law, provided for in a contract or necessary to conclude a contract.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Location</p>
            <p className="text-justify">
            The Data are processed at the headquarters of the Data Controller and in any other place where the parties involved in the processing are located. For further information, please contact the Owner. The User's Personal Data may be transferred to a country other than that in which the User is located. For further information on the location of the processing, the User may refer to the section on details of the processing of Personal Data. The User has the right to obtain information about the legal basis for the transfer of Data outside the European Union or to an international organization under public international law or formed by two or more countries, such as the ONU, as well as about the security measures taken by the Data Controller to protect the Data. If one of the transfers described above takes place, the User may refer to the respective sections of this document or request information from the Data Controller by contacting him/her at the details given at the beginning.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Period of preservation</p>
            <p className="text-justify">
            The Data are processed and stored for the time required for the purposes for which they were collected. Therefore: <ul className="list-unstyled mb-0"><li>- Personal Data collected for purposes related to the execution of a contract between the Data Controller and the User will be retained until the execution of such contract is completed.</li><li> - Personal Data collected for purposes related to the legitimate interest of the Data Controller will be retained until such interest is satisfied.</li></ul>The User may obtain further information about the legitimate interest pursued by the Owner in the relevant sections of this document or by contacting the Owner. When the processing is based on the User's consent, the Owner may retain the Personal Data for a longer period of time until such consent is revoked. In addition, the Data Controller may be obliged to retain Personal Data for a longer period in compliance with a legal obligation or by order of an authority. At the end of the retention period Personal Data will be deleted. Therefore, at the end of this period the right of access, deletion, rectification and the right to portability of the Data may no longer be exercised.
            </p>

            <p className="h3 mt-4 text-justify">Purposes of Data Processing</p>
            <p className="text-justify">
            User Data is collected to allow the Owner to provide its Services, as well as for the following purposes: Contact the User, Statistics, Interaction with social networks and external platforms and Viewing content from external platforms. To obtain further detailed information on the purposes of the processing and on the Personal Data concretely relevant for each purpose, the User may refer to the relevant sections of this document.
            </p>

            <p className="h3 mt-4 text-justify">Details on the processing of Personal Data</p>
            <p
              className="text-justify"
            >Personal Data is collected for the following purposes and using the following services:</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Contact form (andreabalbo.com)</p>
            <p className="text-justify">
            The User, by filling in the contact form with his/her Data, consents to their use to respond to requests for information, quotes, or any other nature indicated in the header of the form. Personal Data collected: zip code, city, tax code, surname, date of birth, email, User ID, address, country, name, number of employees, fax number, telephone number, VAT number, profession, province, company name, sex, business sector, website, state and various types of Data.
            </p>

            <p className="h3 mt-4 text-justify">Interaction with social networks and external platforms</p>
            <p className="text-justify">
            This type of services allows interactions with social networks, or other external platforms, directly from the pages of andreabalbo.com. The interactions and information acquired by andreabalbo.com are in any case subject to the User's privacy settings related to each social network. In case a service of interaction with social networks is installed, it is possible that, even if Users do not use the service, it will collect traffic data related to the pages where it is installed.
            </p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >Like button and Facebook social widgets (Facebook, Inc.)</p>
            <p className="text-justify">
            The "Like" button and Facebook social widgets are social network interaction services provided by Facebook, Inc. Personal Data Collected: Cookies and Usage Data. Place of Processing: United States –
              <a
                href="https://www.facebook.com/privacy/explanation"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >Linkedin social button and widgets (LinkedIn Corporation)</p>
            <p className="text-justify">
            The LinkedIn button and social widgets are LinkedIn social network interaction services provided by LinkedIn Corporation. Personal Data Collected: Cookies and Usage Data. Place of Processing: United States -
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >Tweet button and social Twitter widgets (Twitter, Inc.)</p>
            <p className="text-justify">
            The Tweet button and social widgets of Twitter are services of interaction with the social network Twitter, provided by Twitter, Inc. Personal Data Collected: Cookies and Usage Data. Place of Processing: United States -
              <a
                href="https://twitter.com/privacy"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >YouTube social button and widgets (Google Inc.)</p>
            <p className="text-justify">
            The YouTube button and social widgets are YouTube social network interaction services provided by Google Inc. Personal data collected: Usage data. Place of processing: United States -
              <a
                href="https://www.google.com/intl/it/policies/privacy/"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>

            <p className="h3 mt-4 text-justify">Statistics</p>
            <p
              className="text-justify"
            >The services contained in this section allow the Data Controller to monitor and analyze traffic data and serve to keep track of the User's behaviour.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Google Analytics (Google Inc.)</p>
            <p className="text-justify">
            Google Analytics is a web analysis service provided by Google Inc. ("Google"). Google uses the Personal Data collected for the purpose of tracking and examining your use of andreabalbo.com, compiling reports and sharing it with other services developed by Google. Google may use Personal Data to contextualize and personalize ads in its advertising network. Personal Data collected: Cookies and Usage Data. Place of processing: United States -
              <a
                href="https://www.google.com/intl/it/policies/privacy/"
                className="dark-link"
              >Privacy Policy</a> –
              <a href="https://tools.google.com/dlpage/gaoptout?hl=it" className="dark-link">Opt Out</a>. Subject adhering to Privacy Shield.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Wordpress Stats (Automattic Inc.)</p>
            <p className="text-justify">
            Wordpress Stats is a statistics service provided by Automattic Inc. Personal Data collected: Cookies and Usage Data. Place of processing: United States -
              <a
                href="https://automattic.com/privacy/"
                className="dark-link"
              >Privacy Policy</a>
            </p>

            <p className="h3 mt-4 text-justify">Viewing content from external platforms</p>
            <p className="text-justify">
            This type of services allows you to view content hosted on external platforms directly from the pages of andreabalbo.com and to interact with them. In case a service of this type is installed, it is possible that, even if Users do not use the service, it will collect traffic data related to the pages where it is installed.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Google Fonts (Google Inc.)</p>
            <p className="text-justify">
            Google Fonts is a font style display service run by Google Inc. that allows andreabalbo.com to integrate such content within its pages. Personal Data collected: Usage data and various types of Data as specified in the privacy policy of the service. Place of processing: United States -
              <a
                href="https://www.google.com/intl/policies/privacy/"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >MailChimp (The Rocket Science Group, LLC.)</p>
            <p className="text-justify">
            MailChimp is an address management and email message sending service provided by The Rocket Science Group, LLC. Personal Data collected: Last Name, Cookies, Date of Birth, Usage Data, Email, Address, Country, First Name, Phone Number, Company Name, Sex, Username and various types of Data. Place of Processing: United States -
              <a
                href="https://mailchimp.com/legal/privacy/"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>

            <p className="h3 mt-4 text-justify">Contact management and message sending</p>
            <p className="text-justify">
            This type of service allows you to manage a database of email contacts, telephone contacts or any other type of contacts used to communicate with the User. These services may also make it possible to collect data relating to the date and time the User displays messages, as well as the User's interaction with them, such as information about clicks on links inserted in messages.
            </p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >MailChimp (The Rocket Science Group, LLC.)</p>
            <p className="text-justify">
            MailChimp is an address management and email message sending service provided by The Rocket Science Group, LLC. Personal Data collected: Last Name, Cookies, Date of Birth, Usage Data, Email, Address, Country, First Name, Phone Number, Company Name, Sex, Username and various types of Data. Place of Processing: United States -
              <a
                href="https://mailchimp.com/legal/privacy/"
                className="dark-link"
              >Privacy Policy</a>. Subject adhering to Privacy Shield.
            </p>

            <p className="h3 mt-4 text-justify">Platform and hosting services</p>
            <p
              className="text-justify"
            >These services aim to host and operate key components of andreabalbo.com, making it possible to deliver andreabalbo.com from a single platform. These platforms provide the Owner with a wide range of tools such as, for example, analytical tools, user registration management, comment and database management, e-commerce, payment processing, etc. The use of such tools involves the collection and processing of Personal Data. Some of these services operate through servers located geographically in different locations, making it difficult to determine the exact location where Personal Data is stored.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">WordPress.com (Automattic Inc.)</p>
            <p className="text-justify">
            WordPress.com is a platform provided by Automattic Inc. that allows the Owner to develop, operate and host andreabalbo.com. Personal Data collected: various types of Data as specified in the privacy policy of the service. Place of processing: United States -
              <a
                href="https://automattic.com/privacy/"
                className="dark-link"
              >Privacy Policy</a>.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Livestats Aruba (Aruba)</p>
            <p className="text-justify">
            Livestats is a statistics service provided by Aruba S.r.l. Personal Data collected: Cookies and Usage Data. Place of processing: Italy -
              <a
                href="https://kb.aruba.it/kb/a293/privacy-policy.aspx"
                className="dark-link"
              >Privacy Policy</a>.
            </p>

            <p className="h3 mt-4 text-justify">User Rights</p>
            <p className="text-justify mb-0">
            Users may exercise certain rights with reference to the Data processed by the Owner. In particular, the User has the right to:
            </p>
            <ul className="list-unstyled mb-0">
              <li>- revoke consent at any time. The User may revoke the consent to the processing of his/her Personal Data previously expressed.</li>
              <li>- oppose the processing of your Data. The User may object to the processing of his/her Data when this is done on a legal basis other than consent. Further details on the right to object are indicated in the section below.</li>
              <li>- access your Data. The User has the right to obtain information on the Data processed by the Data Controller, on certain aspects of the processing and to receive a copy of the Data processed.</li>
              <li>- check and ask for rectification. The User may verify the correctness of his/her Data and request that it be updated or corrected.</li>
              <li>- obtain the limitation of treatment. When certain conditions are met, the User may request the limitation of the processing of his/her Data. In this case, the Data Controller will not process the Data for any other purpose than their storage.</li>
              <li>- obtain the deletion or removal of your Personal Data. When certain conditions are met, the User may request the deletion of his/her Personal Data by the Data Controller.</li>
              <li>- receive your Data or have it transferred to another data controller. The User has the right to receive his or her Data in a structured, commonly used and machine-readable format and, where technically feasible, to have it transferred without hindrance to another data controller. This provision is applicable when the Data are processed by automated tools and the processing is based on the User's consent, on a contract to which the User is a party or on contractual measures connected to it.</li>
              <li>- propose a complaint. The User may lodge a complaint with the competent data protection supervisory authority or take legal action.</li>
            </ul>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Details of the right of opposition</p>
            <p className="text-justify">
            When Personal Data are processed in the public interest, in the exercise of public powers vested in the Owner or to pursue a legitimate interest of the Owner, Users have the right to object to the processing for reasons related to their particular situation. Users are reminded that, should their Data be processed for direct marketing purposes, they may object to the processing without giving any reasons. To find out if the Data Controller processes data for direct marketing purposes, Users may refer to the respective sections of this document.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">How to exercise your rights</p>
            <p
              className="text-justify"
            >To exercise the User's rights, Users may address a request to the contact details of the Owner indicated in this document. Requests are deposited free of charge and processed by the Owner as soon as possible, in any case within one month.</p>

            <p className="h3 mt-4 text-justify">More information about the treatment</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Defense in court</p>
            <p className="text-justify">
            The User's Personal Data may be used by the Owner in court or in the preparatory stages of its possible establishment for the defense against abuse in the use of andreabalbo.com or related Services by the User. The User declares to be aware that the Owner may be obliged to disclose the Data by order of the public authorities.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Specific information</p>
            <p
              className="text-justify"
            >At the User's request, in addition to the information contained in this privacy policy, andreabalbo.com may provide the User with additional and contextual information regarding specific Services, or the collection and processing of Personal Data.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">System log and maintenance</p>
            <p
              className="text-justify"
            >For needs related to operation and maintenance, andreabalbo.com and any third party services used by it may collect system logs, i.e. files that record interactions and may also contain Personal Data, such as the User IP address.</p>
            <p
              className="font-weight-bold mt-3 mb-0 text-justify"
            >Information not contained in this policy</p>
            <p
              className="text-justify"
            >Further information in relation to the processing of Personal Data may be requested at any time from the Data Controller using the contact details.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Answer to "Do Not Track" requests</p>
            <p className="text-justify">
            Andreabalbo.com does not support "Do Not Track" requests. To find out if any third party services used support them, the User is invited to consult the respective privacy policy.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Changes to this privacy policy</p>
            <p className="text-justify">
            The Data Controller reserves the right to make changes to this privacy policy at any time by informing Users on this page and, if possible, on andreabalbo.com and, if technically and legally feasible, by sending a notification to Users through one of the contact details in the Data Controller's possession. Therefore, please consult this page regularly, referring to the date of the last modification indicated at the bottom. If the modifications involve treatments whose legal basis is the consent, the Owner will again collect the User's consent, if necessary.
            </p>

            <p className="h3 mt-4 text-justify">Definitions and legal references</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Personal Data (or Data)</p>
            <p
              className="text-justify"
            >Personal data is any information that, directly or indirectly, even in connection with any other information, including a personal identification number, makes a natural person identified or identifiable.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Usage Data</p>
            <p
              className="text-justify"
            >This information is automatically collected through andreabalbo.com (also from third party applications integrated into andreabalbo.com), including: IP addresses or domain names of computers used by the User connecting with andreabalbo.com, addresses in URI (Uniform Resource Identifier) notation, the time of the request, the method used to forward the request to the server, the size of the file obtained in response, the numerical code indicating the status of the response from the server (successful, error, etc.). ) the country of origin, the characteristics of the browser and operating system used by the visitor, the various temporal connotations of the visit (e.g. the time spent on each page) and details of the itinerary followed within the Application, with particular reference to the sequence of pages consulted, the parameters relating to the operating system and the User's computer environment.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">User</p>
            <p
              className="text-justify"
            >The individual who uses andreabalbo.com that, unless otherwise specified, coincides with the Interested Party.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Interested Party</p>
            <p className="text-justify">
            The natural person to whom the Personal Data refers.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Data Processor (or Manager)</p>
            <p className="text-justify">
            The natural person, legal entity, public administration and any other body that processes personal data on behalf of the Data Controller, as set out in this privacy policy.
            </p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Owner of the Treatment (or Owner)</p>
            <p
              className="text-justify"
            >The natural or legal person, public authority, service or other body that, individually or jointly with others, determines the purposes and means of the processing of personal data and the instruments adopted, including security measures relating to the operation and use of andreabalbo.com. The Data Controller, unless otherwise specified, is the owner of andreabalbo.com.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">andreabalbo.com</p>
            <p
              className="text-justify"
            >The hardware or software tool through which Users' Personal Data is collected and processed.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Service</p>
            <p
              className="text-justify"
            >The Service provided by andreabalbo.com as defined in the relevant terms (if any) on this site/application.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">European Union (o UE)</p>
            <p
              className="text-justify"
            >Unless otherwise specified, any reference to the European Union contained in this document is understood to extend to all current member states of the European Union and the European Economic Area.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Cookie</p>
            <p
              className="text-justify"
            >Small portion of data stored inside the User's device.</p>
            <p className="font-weight-bold mt-3 mb-0 text-justify">Legal references</p>
            <p className="text-justify">
            This privacy policy is drawn up on the basis of multiple legal systems, including Articles 13 and 14 of Regulation (EU) 2016/679. Unless otherwise specified, this privacy policy concerns exclusively andreabalbo.com.
            </p>
            
          </div>
        </div>
      </div>

    </Layout>
  );
}
